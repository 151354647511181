<template>
  <page-container type="fullframe">
    <div class="relative bg-white h-100-p">
      <split-pane split-to="columns" :size="330" :allow-resize="true">
        <template slot="firstPane">
          <slot name="leftPane"></slot>
        </template>

        <template slot="secondPane">
          <split-pane slot="secondPane" units="percents" split-to="rows" :size="60" :allow-resize="true">
            <template slot="firstPane">
              <slot name="topPane"></slot>
            </template>

            <template slot="secondPane">
              <slot name="bottomPane"></slot>
            </template>
          </split-pane>
        </template>
      </split-pane>
    </div>
  </page-container>
</template>

<script>
import SplitPane from '@/components/SplitPane'
export default {
  data () {
    return {
      leftSize: 16,
      leftMinWidth: '307px',
      topSize: 60
    }
  },
  methods: {
    toggleLeftPane () {
      if (this.leftSize === 16) {
        this.leftMinWidth = '0'
        this.leftSize = 0
      } else {
        this.leftMinWidth = '307px'
        this.leftSize = 16
      }
    },
    toggleBtmPane () {
      if (this.topSize === 60) {
        this.topSize = 0
      } else {
        this.topSize = 60
      }
    }
  },
  components: {
    SplitPane
  }
}
</script>
