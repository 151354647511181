<template>
  <layout-monitor>
    <div slot="leftPane" class="flex-column p-15 h-100-p">
      <div style="height:calc(100% - 202px)">
        <bus-tree @radio-change="radioChange" check-type="radio" />
      </div>
      <div class="border-top mt-10 pt-10">
        <div class="flex align-center mb-10">
          <span class="text-right w-60 fs-12 mr-5">回放车辆：</span>
          <el-input class="flex-1" v-model="busTrackForm.busLicPlate" :readonly="true" placeholder="请在上方[车辆列表]选择[车辆]" />
        </div>
        <div class="flex align-center mb-10">
          <span an class="text-right w-60 fs-12 mr-5">开始时间：</span>
          <el-date-picker v-model="busTrackForm.startTime" default-time="00:00:00" class="flex-1" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="开始时间"></el-date-picker>
        </div>
        <div class="flex align-center mb-10">
          <span class="text-right w-60 fs-12 mr-5">结束时间：</span>
          <el-date-picker v-model="busTrackForm.endTime" default-time="23:59:59"  class="flex-1" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="结束时间" ></el-date-picker>
        </div>
        <!-- <div class="flex align-center mb-10">
            <span class="text-right w-60 fs-12 mr-5">地址解析：</span>
            <el-checkbox v-model="busTrackForm.geoAddress">
              <span class="fs-12">解析</span>
            </el-checkbox>
          </div> -->
        <div class="flex align-center mb-10">
          <span class="text-right w-60 fs-12 mr-5 ">停车点：</span>
          <span class="fs-12 mr-10">停车时间超过</span>
          <el-input-number v-model="busTrackForm.stopTimeSecond" class="flex-1" :min="0" />
          <div class="fs-12" style="padding-left: 10px">秒</div>
        </div>
        <el-button class="w-100-p" type="primary" icon="el-icon-search" @click="onSearchTrack" :loading="loading">查询</el-button>
      </div>
    </div>

    <!-- 地图 -->
    <div slot="topPane" class="h-100-p">
      <BaseMap
        ref="myMap"
        @change="mapBaseChange"
      ></BaseMap>
      <!-- <wazert-map ref="myMap" class="h-100-p" @baselayer-change="mapBaseChange" /> -->
    </div>

    <!-- 列表 -->
    <div slot="bottomPane" class="flex flex-column h-100-p">
      <resize-wrap class="h-100-p">
        <template>
          <div class="ralative">
            <el-tabs type="border-card" v-model="tabsName">
              <el-tab-pane label="轨迹点列表" name="all"></el-tab-pane>
              <el-tab-pane label="停车点列表" name="stop"></el-tab-pane>
            </el-tabs>

            <div class="flex align-center fs-12 tab-tools" >
              <!-- 报警 -->
              <span>回放方式：</span>
              <el-select class="w-100" v-model="showType" @change="showTypeChange">
                <el-option label="全程预览" value="1"/>
                <el-option label="动态回放" value="2"/>
              </el-select>
              <div class="flex-1 flex align-center justify-end h-100-p">
                <template v-if="showType==2">
                  <el-divider direction="vertical" />
                  <span style="fs-12">回放速率：</span>
                  <el-select v-model="busTrackForm.playRate" style="width: 80px">
                    <el-option label="1X" value="1000"/>
                    <el-option label="5X" value="200"/>
                    <el-option label="10X" value="100"/>
                    <el-option label="20X" value="50"/>
                    <el-option label="50X" value="20"/>
                    <el-option label="100X" value="10"/>
                  </el-select>
                  <el-divider direction="vertical" />
                  <el-button icon="el-icon-video-pause" type="primary" @click="pauseMoveMarker" >{{stopPlay?'继续':'暂停'}}</el-button>
                  <el-slider
                    class="flex-1 ml-20 mr-15"
                    v-model="speedProcess"
                    :max="busTrackPoints.length"
                    :format-tooltip="formatTooltip"
                    @change="sliderChange"
                  />
                  <el-button icon="el-icon-delete" type="danger" @click="clearPathData">清除</el-button>
                </template>
                <el-button title="导出轨迹点列表" type="primary" icon="el-icon-download" @click="exportDataEvent" v-if="loading===false" :disabled="busTrackTableData.length===0">导出</el-button>
              </div>
            </div>
          </div>
          <div :style="{height:'calc(100% - 40px)'}" v-if="tabsName=='all'">
            <wz-table
              ref="busTrackGrid"
              @cell-dblclick="cellDBLClickEvent"
              auto-resize
              height="auto"
              :data="busTrackTableData">
              <vxe-table-column type="seq" title="序号" width="60"/>
              <vxe-table-column field="compName" title="企业/车队" show-overflow="title" min-width="135"/>
              <vxe-table-column field="busOwnerCode" title="自编号" show-overflow="title" min-width="60"/>
              <vxe-table-column field="busLicPlate" title="车牌号" show-overflow="title" min-width="60"/>
              <vxe-table-column field="speed" title="速度(km/h)" show-overflow="title" min-width="60"/>
              <vxe-table-column field="time" title="时间" show-overflow="title" min-width="135"/>
              <vxe-table-column field="stopTime" title="停车时长" show-overflow="title" min-width="100"/>
              <vxe-table-column field="lat" title="纬度" show-overflow="title" min-width="60"/>
              <vxe-table-column field="lng" title="经度" show-overflow="title" min-width="60"/>
              <!-- <vxe-table-column field="mileage" title="里程(km)" show-overflow="title" min-width="60" :formatter="formatterMileage"/> -->
              <vxe-table-column field="mileageKM" title="里程(km)" show-overflow="title" min-width="60"></vxe-table-column>
              <vxe-table-column field="address" title="地址" show-overflow="title" min-width="200" v-if="geoAddress"/>
            </wz-table>
          </div>
          <div :style="{height:'calc(100% - 40px)'}" v-else>
            <wz-table
              ref="busTrackGrid"
              auto-resize
              height="auto"
              @cell-dblclick="cellDBLClickEvent"
              :data="busStopTableData">
              <vxe-table-column type="seq" title="序号" width="60"/>
              <vxe-table-column field="compName" title="企业/车队" show-overflow="title" min-width="135" />
              <vxe-table-column field="busOwnerCode" title="自编号" show-overflow="title" min-width="60">
                <template v-slot>{{currentBus.detail.ownerCode}}</template>
              </vxe-table-column>
              <vxe-table-column field="busLicPlate" title="车牌号" show-overflow="title" min-width="60">
                <template v-slot>{{currentBus.detail.busLicPlate}}</template>
              </vxe-table-column>
              <vxe-table-column field="speed" title="速度(km/h)" show-overflow="title" min-width="60"/>
              <vxe-table-column field="time" title="时间" show-overflow="title" min-width="135"/>
              <vxe-table-column field="stopTime" title="停车时长" show-overflow="title" min-width="100"/>
              <vxe-table-column field="lat" title="纬度" show-overflow="title" min-width="60"/>
              <vxe-table-column field="lng" title="经度" show-overflow="title" min-width="60"/>
              <vxe-table-column field="mileageKM" title="里程(km)" show-overflow="title" min-width="60"></vxe-table-column>
              <vxe-table-column field="address" title="地址" show-overflow="title" min-width="200" v-if="geoAddress"/>
            </wz-table>
          </div>
        </template>
      </resize-wrap>
    </div>
  </layout-monitor>
</template>

<script>
import BaseMap from '@/components/BaseMap'
import BusTree from '../components/BusTree'
import * as maptalks from 'maptalks'
import LayoutMonitor from '../components/Layout'
import gcoord from 'gcoord'
import { getBusTrack } from '@/apis/monitor/carTrack'
import XEUtils from 'xe-utils'
export default {
  data () {
    return {
      mapIns: null,
      layerType: '', // 底图类型
      tabsName: 'all',
      mapType: 0,
      busTrackForm: {
        busLicPlate: '',
        startTime: '',
        endTime: '',
        playRate: '100',
        bus: null,
        // zoom: 14,
        geoAddress: false,
        stopTimeSecond: 300
      },
      busTrackPoints: [],
      busMarker: null,
      currentIndex: 0,
      speedProcess: 0,
      busMoveTimeout: null,
      stopPlay: true,
      busTrackTableData: [],
      currentBus: {
        detail: {
          compName: '',
          busLicPlate: '',
          ownerCode: ''
        }
      },
      loading: false,
      busStopTableData: [],
      busMarkerSet: {
        icon: 1,
        size: 2
      },
      markerSetting: {
        running: require('@/assets/imgs/monitor/bus_running.png')
      },
      talksMapLayer: 'baidu',
      trackLayer: null, // 轨迹图层
      allPathLine: null, // 全部线路
      busTrackCoordinates: [],
      busStopMarkerMap: new Map(),
      showType: '1',
      playbackLines: [], // 多个线轨迹回放
      lineColor: '#123458',
      lineBaseColor: '#123458',
      allPathBaseLine: null,
      geoAddress: false,
      geoAddressIndex: 0,
      tabs: [
        { id: 'allPoint', name: '轨迹点列表' },
        { id: 'stopPoint', name: '停车点列表' }
      ]
    }
  },
  created () {
    const now = new Date()
    this.busTrackForm.startTime = XEUtils.toDateString(now, 'yyyy-MM-dd') + ' 00:00:00'
    this.busTrackForm.endTime = XEUtils.toDateString(now, 'yyyy-MM-dd') + ' 23:59:59'

    window.closeInfoWin3 = this.closeInfoWin2

    this.$once('hook:beforeDestroy', () => {
      window.closeInfoWin3 = null
      if (this.busMoveTimeout) clearTimeout(this.busMoveTimeout)
    })

    this.$nextTick(() => {
      const { mapIns, layerType } = this.$refs.myMap.init()
      this.mapIns = mapIns
      this.layerType = layerType
    })
  },

  methods: {
    clearPathData () {
      this.speedProcess = 0
      this.sliderChange(0)
      this.busTrackTableData = []
      this.busStopTableData = []
      // clearTimeout(this.busMoveTimeout)
      // this.busMoveTimeout = null
      // this.busTrackPoints = []
      // this.busTrackTableData = []
      // this.busStopTableData = []
      // this.busStopMarkerMap.clear()
      // if (this.trackLayer != null) {
      //   this.trackLayer.clear()
      // }
      // this.stopPlay = true
      // this.currentIndex = 0
      // this.playbackLines.forEach(line => {
      //   line.remove()
      // })
      // this.playbackLines = []
    },

    closeInfoWin2 (id) {
      const stopMarker = this.busStopMarkerMap.get(id)
      if (stopMarker) {
        stopMarker.closeInfoWindow()
      } else {
        this.busMarker.closeInfoWindow()
      }
    },

    // 进度条Tooltip
    formatTooltip (val) {
      const busTrackPoint = this.busTrackPoints[val - 1]
      return busTrackPoint ? busTrackPoint.time : val
    },

    // 查询轨迹
    onSearchTrack () {
      // 不通过验证就返回
      if (this.verifyForm()) return

      const { id, startTime, endTime } = this.busTrackForm
      this.loading = true
      getBusTrack({ id, startTime, endTime }).then((res) => {
        if (res.code == 200) {
          this.currentBus = this.busTrackForm.bus
          console.log(res.data, 'res.data')
          this.busTrackPoints = res.data.map(item => {
            item.mileageKM = item.mileage ? XEUtils.round(item.mileage / 1000, 2) : 0
            return item
          })
          this.busTrackTableData = []
          this.busStopTableData = []
          this.stopPlay = true
          this.allPathLine = null
          this.geoAddressIndex = 0

          if (this.busMoveTimeout) {
            clearTimeout(this.busMoveTimeout)
            this.busMoveTimeout = null
          }

          if (this.busTrackPoints.length === 0) {
            this.$message({ message: '暂无轨迹数据', type: 'warning' })
            return
          }
          this.stopPlay = false
          this.startDrawLine()
        } else {
          this.$message({ message: res.msg, type: 'error' })
        }
      }).finally(() => {
        this.loading = false
      })
    },

    startDrawLine () {
      if (this.stopPlay) return
      this.busTrackTableData = []
      this.busStopTableData = []
      this.stopPlay = false
      this.busStopMarkerMap.clear()

      if (this.trackLayer == null) {
        this.trackLayer = new maptalks.VectorLayer('vector').addTo(this.mapIns)
      }

      const linePoints = []
      let index = 0
      this.busTrackPoints.forEach(item => {
        // item.compName = this.currentBus.detail.compName
        item.busOwnerCode = this.currentBus.detail.ownerCode
        item.busLicPlate = this.currentBus.detail.busLicPlate
        item.id = index

        if (item.lat !== 0 && item.lng !== 0) {
          const position = this.formatLngLat([item.lng, item.lat])
          linePoints.push(position)
        }
        index++
      })
      this.busTrackCoordinates = linePoints

      setTimeout(() => {
        if (this.showType === '1') {
          this.startDrawAllPathLine() // 全程预览
        } else {
          this.startDrawPlabackLine() // 动态回放
        }
      }, 10)

      // if (this.geoAddress) {
      //   if (index > 20) {
      //     for (let i = 0; i < 10; i++) {
      //       setTimeout(() => {
      //         this.geocoder()
      //       }, 200 * i)
      //     }
      //   } else {
      //     this.geocoder()
      //   }
      // }
    },

    // 动态路线
    startDrawPlabackLine () {
      if (this.busMoveTimeout) {
        clearTimeout(this.busMoveTimeout)
        this.busMoveTimeout = null
      }
      this.busStopTableData = []
      this.busTrackTableData = []
      this.busStopMarkerMap.clear()
      this.stopPlay = false
      this.playbackLines.forEach(line => {
        line.remove()
      })
      this.playbackLines = []
      if (this.trackLayer !== null) {
        this.trackLayer.clear()
      }
      if (this.busTrackPoints.length === 0) return
      const firstPoint = this.busTrackPoints[0]

      const startPosition = this.formatLngLat([firstPoint.lng, firstPoint.lat])
      // 边走边画 线路
      const line = new maptalks.LineString([startPosition], {
        arrowStyle: [2, 3], // arrow-style : now we only have classic
        arrowPlacement: 'point', // arrow's placement: vertex-first, vertex-last, vertex-firstlast, point
        editable: false,
        enableClip: false,
        enableSimplify: true,
        symbol: {
          lineColor: this.lineColor,
          lineWidth: 2
        }
      })
      line.addTo(this.trackLayer, true)
      this.playbackLines.push(line)
      this.currentIndex = 1
      this.speedProcess = 1
      this.busTrackTableData.push(firstPoint)
      if (firstPoint.stop) {
        this.addStopMarker(firstPoint)
        this.busStopTableData.push(firstPoint)
      }
      this.drawStartEndMarker()
      const mapIns = this.mapIns
      const zoom = mapIns.getZoom() - 4
      mapIns.setCenterAndZoom(startPosition, zoom)
      this.startMoveMarker()
    },

    startMoveMarker () {
      if (this.stopPlay || this.busTrackPoints.length === 0) return

      if (this.currentIndex === this.busTrackPoints.length) {
        this.$message.success('回放完毕')
      } else {
        const busTrackPoint = this.busTrackPoints[this.currentIndex]
        if (busTrackPoint && busTrackPoint.lat !== 0 && busTrackPoint.lng !== 0) {
          this.busMarker.setCoordinates(this.formatLngLat([busTrackPoint.lng, busTrackPoint.lat]))
          const symbols = this.busMarker.getSymbol()
          symbols[0].markerRotation = 360 - busTrackPoint.angle
          this.busMarker.setSymbol(symbols)

          const infoWindow = this.busMarker.getInfoWindow()
          infoWindow.options.content = this.getInfoWindowHtml(busTrackPoint)
          if (infoWindow.isVisible()) {
            this.busMarker.openInfoWindow()
          }

          const cline = this.playbackLines[this.playbackLines.length - 1]
          if (cline) {
            const coordinates = cline.getCoordinates()
            const cposition = this.formatLngLat([busTrackPoint.lng, busTrackPoint.lat])
            if (coordinates.length > 100) {
              const line = new maptalks.LineString([coordinates[coordinates.length - 1], cposition], {
                arrowStyle: [2, 3], // arrow-style : now we only have classic
                arrowPlacement: 'point', // arrow's placement: vertex-first, vertex-last, vertex-firstlast, point
                editable: false,
                enableClip: false,
                enableSimplify: true,
                symbol: {
                  lineColor: this.lineColor,
                  lineWidth: 2
                }
              })
              line.addTo(this.trackLayer, false)
              this.playbackLines.push(line)
            } else {
              coordinates.push(cposition)
              cline.setCoordinates(coordinates)
            }
          }

          const extent = this.busMarker.getMap().getExtent()
          if (!extent.contains(this.busMarker.getCoordinates())) {
            this.busMarker.getMap().setCenterAndZoom(this.busMarker.getCoordinates(), this.busMarker.getMap().getZoom())
          }
        }

        if (busTrackPoint && busTrackPoint.stop && (busTrackPoint.stopTimeSecond > this.busTrackForm.stopTimeSecond)) {
          this.addStopMarker(busTrackPoint)
          this.busStopTableData.unshift(busTrackPoint)
        }

        if (busTrackPoint) {
          this.busTrackTableData.unshift(busTrackPoint)
          this.currentIndex++
          this.speedProcess = parseInt(this.currentIndex)
        }

        this.busMoveTimeout = setTimeout(() => {
          this.startMoveMarker()
        }, parseInt(this.busTrackForm.playRate))
      }
    },

    // 开始全程预览线路
    startDrawAllPathLine () {
      console.time('startDrawAllPathLine')
      if (this.busMoveTimeout) {
        clearTimeout(this.busMoveTimeout)
        this.busMoveTimeout = null
      }
      this.busStopTableData = []
      this.busTrackTableData = []
      this.busStopMarkerMap.clear()
      this.stopPlay = false
      if (this.trackLayer !== null) {
        this.trackLayer.clear()
      }
      if (this.busTrackPoints.length === 0) {
        return
      }
      // 绘制全部轨迹线
      const line = new maptalks.LineString(this.busTrackCoordinates, {
        editable: false,
        symbol: {
          linePatternFile: require('@/assets/imgs/monitor/track_line_green.png'),
          linePatternDx: 0,
          lineWidth: 8
        }
      })
      this.allPathLine = line
      line.addTo(this.trackLayer, true)
      line.animate({
        symbol: { linePatternDx: 20 }
      }, { repeat: true })

      this.busTrackPoints.forEach(item => {
        if (item.stop && (item.stopTimeSecond > this.busTrackForm.stopTimeSecond)) {
          this.addStopMarker(item)
          this.busStopTableData.unshift(item)
        }
        this.busTrackTableData.unshift(item)
      })

      this.currentIndex = this.busTrackPoints.length
      this.drawStartEndMarker()
      console.timeEnd('startDrawAllPathLine')
    },

    drawStartEndMarker () {
      const firstPoint = this.busTrackPoints[0]
      const startPosition = this.formatLngLat([firstPoint.lng, firstPoint.lat])
      const markerStart = new maptalks.Marker(
        startPosition,
        {
          symbol: {
            markerFile: require('@/assets/imgs/monitor/marker_start.png'),
            markerWidth: 48,
            markerHeight: 48,
            markerDx: 0,
            markerDy: 2,
            markerOpacity: 1
          }
        }
      ).addTo(this.trackLayer)
      markerStart.bringToFront()

      // 终点
      const endPoint = this.busTrackPoints[this.busTrackPoints.length - 1]
      const endPosition = this.formatLngLat([endPoint.lng, endPoint.lat])
      const markerEnd = new maptalks.Marker(
        endPosition,
        {
          symbol: {
            markerFile: require('@/assets/imgs/monitor/marker_end.png'),
            markerWidth: 48,
            markerHeight: 48,
            markerDx: 0,
            markerDy: 2,
            markerOpacity: 1
          }
        }
      ).addTo(this.trackLayer)
      markerEnd.bringToFront()

      // 回放点
      this.busMarker = new maptalks.Marker(
        this.formatLngLat([firstPoint.lng, firstPoint.lat]),
        {
          symbol: [{
            markerFile: this.markerSetting.running,
            markerWidth: this.markerSetting.markerWidth,
            markerHeight: this.markerSetting.markerHeight,
            markerHorizontalAlignment: 'middle',
            markerVerticalAlignment: 'middle',
            markerRotation: 360 - firstPoint.angle
          }]
        }
      )
      const options = {
        autoPan: true,
        autoOpenOn: 'click', // set to null if not to open window when clicking on map
        single: true,
        custom: true,
        width: 300,
        height: 300,
        dx: -3,
        dy: -12,
        content: this.getInfoWindowHtml(firstPoint)
      }
      const infoWindow = new maptalks.ui.InfoWindow(options)
      this.busMarker.setInfoWindow(infoWindow)
      this.busMarker.addTo(this.trackLayer)
      this.busMarker.bringToFront()
    },

    addStopMarker (busTrackPoint) {
      const position = this.formatLngLat([busTrackPoint.lng, busTrackPoint.lat])
      const markerStop = new maptalks.Marker(
        position,
        {
          symbol: {
            markerFile: require('@/assets/imgs/monitor/marker_stop.png'),
            markerWidth: 24,
            markerHeight: 33,
            markerDx: 0,
            markerDy: 1,
            markerOpacity: 1
          }
        }
      ).addTo(this.trackLayer)
      markerStop.bringToFront()
      this.busStopMarkerMap.set(busTrackPoint.id, markerStop)
      const options = {
        autoPan: false,
        autoOpenOn: 'click',
        single: true,
        custom: true,
        width: 300,
        height: 300,
        dx: -3,
        dy: -12,
        content: this.getInfoWindowHtml(busTrackPoint)
      }
      const infoWindow = new maptalks.ui.InfoWindow(options)
      markerStop.setInfoWindow(infoWindow)
    },

    getInfoWindowHtml (trackPoint) {
      // 详情显示内容
      const html = `
      <div class="marker-card">
        <i class="marker-card-close el-icon-close fs-14" onclick="closeInfoWin3(${trackPoint.id})"></i>
        <!-- 字段 -->
        <div class="flex flex-column fs-12 bgcolor-white w-100-p">
          <div class="flex align-center fs-12 mt-2 mb-2">车牌号: ${this.currentBus.detail.busLicPlate}</div>
          <div class="flex align-center fs-12 mt-2 mb-2">自编号: ${this.currentBus.detail.ownerCode}</div>
          <div class="flex align-center fs-12 mt-2 mb-2">企业/车队: ${trackPoint.compName}</div>
          <div class="flex align-center fs-12 mt-2 mb-2">速度: ${trackPoint.speed}km/h</div>
          <div class="flex align-center fs-12 mt-2 mb-2">时间: ${trackPoint.time}</div>` +
            (trackPoint.stop ? `<div class="flex align-center fs-12 mt-2 mb-2">停车: ${trackPoint.stopTime}</div>` : '') +
        `</div>
      </div>`.trim()
      return html
    },

    mapBaseChange () {

    },

    // 验证表单必填，true表示不通过验证
    verifyForm () {
      const waitVerifys = [
        { prop: 'id', message: '请选择车辆' },
        { prop: 'startTime', message: '请选择开始时间' },
        { prop: 'endTime', message: '请选择结束时间' }
      ]
      const busTrackForm = this.busTrackForm
      let notPass = waitVerifys.some(({ prop, message }) => {
        const value = busTrackForm[prop]
        if (!value) this.$message.error(message)
        return !value
      })
      if (!notPass) {
        const dateDiff = XEUtils.getDateDiff(busTrackForm.startTime, busTrackForm.endTime)
        console.log(dateDiff, 'dateDiff')
        const ts = 7 * 24 * 60 * 60 * 1000 // 7天
        if (!dateDiff.done) {
          this.$message.error('开始时间不能晚于结束时间')
          // 没有通过验证
          notPass = true
        } else if (dateDiff.time > ts) {
          this.$message.error('查询时间段不能超过7天')
          notPass = true
        }
      }
      return notPass
    },

    radioChange (node) {
      console.log(node, 'node')
      // this.busTrackForm.deviceList = node.deviceList;
      this.busTrackForm.busLicPlate = node.detail.busLicPlate
      this.busTrackForm.id = node.detail.busID
      this.busTrackForm.bus = node
    },

    // wgs84经纬度格式化
    formatLngLat (lngLat) {
      const type = this.layerType
      if (!type || type == 'tianditu') return lngLat

      const props = { baidu: 'BD09', gaode: 'GCJ02' }
      return gcoord.transform(
        lngLat, // 经纬度坐标
        gcoord.WGS84, // 当前坐标系
        gcoord[props[type]] // 目标坐标系
      )
    },

    showTypeChange (val) {
      if (val === '1') {
        this.startDrawAllPathLine()
      } else {
        this.startDrawPlabackLine()
      }
    },

    exportDataEvent () {
      this.$refs.busTrackGrid.$refs.innerTable.exportData({
        filename: '轨迹数据',
        sheetName: '轨迹数据',
        type: 'xlsx'
      })
    },

    sliderChange (val) {
      if (this.busMoveTimeout) {
        clearTimeout(this.busMoveTimeout)
        this.busMoveTimeout = null
      }
      this.stopPlay = true
      this.busStopTableData = []
      this.busTrackTableData = []
      this.busStopMarkerMap.clear()
      this.playbackLines.forEach(line => {
        line.remove()
      })
      this.playbackLines = []

      if (this.trackLayer !== null) {
        this.trackLayer.clear()
      }
      if (this.busTrackPoints.length === 0) return
      const firstPoint = this.busTrackPoints[0]
      const startPosition = this.formatLngLat([firstPoint.lng, firstPoint.lat])

      // 边走边画 线路
      const line = new maptalks.LineString([startPosition], {
        arrowStyle: [2, 3], // arrow-style : now we only have classic
        arrowPlacement: 'point', // arrow's placement: vertex-first, vertex-last, vertex-firstlast, point
        editable: false,
        enableSimplify: true,
        symbol: {
          lineColor: this.lineColor,
          lineWidth: 2
        }
      })
      line.addTo(this.trackLayer, false)
      this.playbackLines.push(line)
      this.busTrackTableData.push(firstPoint)
      if (firstPoint.stop) {
        this.addStopMarker(firstPoint)
        this.busStopTableData.push(firstPoint)
      }
      this.drawStartEndMarker()
      let lastBusTrackPoint = null
      for (let i = 0; i < this.speedProcess; i++) {
        const busTrackPoint = this.busTrackPoints[i]
        lastBusTrackPoint = busTrackPoint
        if (busTrackPoint.lat != 0 && busTrackPoint.lng != 0) {
          const cposition = this.formatLngLat([busTrackPoint.lng, busTrackPoint.lat])
          const cline = this.playbackLines[this.playbackLines.length - 1]
          const coordinates = cline.getCoordinates()
          if (coordinates.length > 100) {
            const line = new maptalks.LineString([coordinates[coordinates.length - 1], cposition], {
              arrowStyle: [2, 3], // arrow-style : now we only have classic
              arrowPlacement: 'point', // arrow's placement: vertex-first, vertex-last, vertex-firstlast, point
              editable: false,
              enableClip: false,
              enableSimplify: true,
              symbol: {
                lineColor: this.lineColor,
                lineWidth: 2
              }
            })
            line.addTo(this.trackLayer, false)
            this.playbackLines.push(line)
          } else {
            coordinates.push(cposition)
            cline.setCoordinates(coordinates)
          }
        }
        if (busTrackPoint.stop && (busTrackPoint.stopTimeSecond > this.busTrackForm.stopTimeSecond)) {
          this.addStopMarker(busTrackPoint)
          this.busStopTableData.unshift(busTrackPoint)
        }
        this.busTrackTableData.unshift(busTrackPoint)
      }
      if (lastBusTrackPoint) {
        this.busMarker.setCoordinates(this.formatLngLat([lastBusTrackPoint.lng, lastBusTrackPoint.lat]))
        const symbols = this.busMarker.getSymbol()
        symbols[0].markerRotation = 360 - lastBusTrackPoint.angle
        this.busMarker.setSymbol(symbols)

        const infoWindow = this.busMarker.getInfoWindow()
        infoWindow.options.content = this.getInfoWindowHtml(lastBusTrackPoint)
        const extent = this.busMarker.getMap().getExtent()
        if (!extent.contains(this.busMarker.getCoordinates())) {
          this.busMarker.getMap().setCenterAndZoom(this.busMarker.getCoordinates(), this.busMarker.getMap().getZoom())
        }
        this.busMarker.getMap().setCenterAndZoom(this.busMarker.getCoordinates(), this.busMarker.getMap().getZoom())
        if (parseInt(this.busTrackForm.playRate) > 99) {
          this.busMarker.openInfoWindow()
        }
      }
      this.currentIndex = val
    },

    pauseMoveMarker () {
      if (!this.stopPlay) {
        clearTimeout(this.busMoveTimeout)
        this.busMoveTimeout = null
        this.playBtnText = '继续'
        this.stopPlay = !this.stopPlay
      } else {
        this.stopPlay = !this.stopPlay
        this.startMoveMarker()
        this.playBtnText = '暂停'
      }
    },

    cellDBLClickEvent ({ row }) {
      if (this.busMoveTimeout) {
        clearTimeout(this.busMoveTimeout)
        this.busMoveTimeout = null
      }
      this.stopPlay = true
      // 如果是停车点
      if (row.stop) {
        const stopMarker = this.busStopMarkerMap.get(row.id)
        if (stopMarker) {
          stopMarker.getMap().setCenterAndZoom(stopMarker.getCoordinates(), 16)
          stopMarker.openInfoWindow()
          return
        }
      }
      this.busMarker.setCoordinates(this.formatLngLat([row.lng, row.lat]))
      const symbols = this.busMarker.getSymbol()
      symbols[0].markerRotation = 360 - row.angle
      this.busMarker.setSymbol(symbols)
      const infoWindow = this.busMarker.getInfoWindow()
      infoWindow.options.content = this.getInfoWindowHtml(row)
      this.busMarker.getMap().setCenterAndZoom(this.busMarker.getCoordinates(), 16)
      this.busMarker.openInfoWindow()
    }
  },
  components: {
    BaseMap,
    BusTree,
    LayoutMonitor
  }
}
</script>

<style lang="scss">
.tree-name {
  user-select: none;
  cursor: pointer;
  padding: 0 5px;
  &.active,
  &:hover {
    background-color: #f5f7fa;
  }
}
.tab-tools {
  position: absolute;
  top:0px;
  left:250px;
  // bottom: 0;
  right:15px;
  height: 40px;
}
.el-tabs--border-card {
  border: 0!important;
}
.el-tabs--border-card {
  height: 100%;
}
.el-tabs--border-card>.el-tabs__content {
  padding: 0!important;
  height: calc(100% - 40px);

  .el-tab-pane {
    height: 100%;
  }
}

.marker-card {
  width: 320px;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
  border-radius: 3px;
  padding: 8px;

  .marker-card-close {
    position: absolute;
    top: 8px;
    right: 10px;
    cursor: pointer;
  }

  .marker-actions {
    border-top: 1px solid #dcdfe6;
    margin-top: 5px;
    padding-top: 5px;
    .marker-action-icon {
      width: 18px;
      height: 18px;
      margin-right: 4px;
    }
    .marker-action-item {
      cursor: pointer;
      border: 1px solid #dcdfe6;
      border-radius: 100px;
      margin: 2px 6px 0 0;
      padding: 2px 6px 2px 2px;
      &:hover {
        background: rgba(#000, .05);
      }
    }
  }
}

</style>
