<template>
  <div
    @mouseover="isMouseOver = true"
    @mouseout="isMouseOver = false"
    class="Resizer flex align-center justify-center"
    :style="resStyle"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'resizer-comp',
  props: [
    'splitTo',
    'resizerColor',
    'resizerBorderColor',
    'resizerThickness',
    'resizerBorderThickness'
  ],
  data () {
    return {
      isMouseOver: false
    }
  },
  methods: {
    evMouseOver () {
      this.isMouseOver = true
    }
  },
  computed: {
    margin () {
      return Math.floor(this.resizerThickness / 2) + this.resizerBorderThickness
    },
    rBorder () {
      if (this.splitTo === 'rows') {
        return { border1: 'top', border2: 'bottom' }
      } else {
        return { border1: 'left', border2: 'right' }
      }
    },
    resStyle: function () {
      const tmpStyle = {}
      tmpStyle['background-color'] = this.resizerColor

      if (this.splitTo === 'rows') {
        tmpStyle.height = this.resizerThickness + 'px'
        // tmpStyle.margin = '-' + this.margin + 'px 0'
        // tmpStyle.padding = '0 ' + this.resizerBorderThickness + 'px'
      } else {
        tmpStyle.width = this.resizerThickness + 'px'
        // tmpStyle.margin = '0 ' + '-' + this.margin + 'px'
        // tmpStyle.padding = this.resizerBorderThickness + 'px 0'
      }

      // if (this.isMouseOver) {
      //   tmpStyle[`border-${this.rBorder.border1}`] = tmpStyle[
      //     `border-${this.rBorder.border2}`
      //   ] =
      //     this.resizerBorderColor +
      //     ' solid ' +
      //     this.resizerBorderThickness +
      //     'px'
      // } else {
      //   tmpStyle[`border-${this.rBorder.border1}`] = tmpStyle[
      //     `border-${this.rBorder.border2}`
      //   ] =
      //     'transparent solid ' + this.resizerBorderThickness + 'px'
      // }

      return tmpStyle
    }
  }
}
</script>

<style scoped>
.Resizer {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 9;
  /* -moz-background-clip: padding-box;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; */
  overflow: hidden;
  border: 1px dashed transparent;
}

.Resizer:hover {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border: 1px dashed #666;
}

.Resizer.rowsres {
  cursor: row-resize;
  width: 100%;
}

.Resizer.columnsres {
  height: 100%;
  cursor: col-resize;
}

.rows {
  transform: translateY(-5px) rotate(90deg);
  transform-origin: center;
}

</style>
